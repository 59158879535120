$(document).ready(function () {
	min = 0; // Minimum of 0
	max = 10; // Maximum of 10


	$(".ticket-action").on("click", "#addTicketJS", function () {
		addTicketData($(this));
	}).on('submit', '#jsTicketForm', function (e) {
		e.preventDefault();
		addTicketData($(this));
		return false;
	});

});

let delay;

function addTicketData(source) {
	if (source.closest('form')) {
		clearTimeout(delay);

		delay = setTimeout(function () {
			let form = source.closest('form');
			let url = form.attr("action");

			let token = form.find("input[name=_token]").val();
			let quantity = parseInt(form.find("input[name=quantity]").val());
			let ticketId = form.find("input[name=ticket_id]").val();

			$(".myAlert-top").show();
			setTimeout(function () {
				$(".myAlert-top").hide();
			}, 1500);

			$.ajax(
				{
					method: "POST",
					url: url,
					data: {_token: token, ticket_id: ticketId, quantity: quantity},
					dataType: "json",
					cache: false,
				})
				.done(function (result) {
					let resposneData = result;
					if (resposneData.status.code === 1) {
						form.find("input[name=quantity]").val(1);

						$('#jsTotalValue').text(resposneData.data.total_value + ' zł');
						$('.shoppingCardCount').text(resposneData.data.card_count);
						$(".myAlert-top").show();
						setTimeout(function(){
							$(".myAlert-top").hide();
						}, 1500);
					}
				})
				.fail(function (result) {
					let errorText = '';
					let resposneData = result.responseJSON;
					if (resposneData.status.code === -10) {
						$.each(resposneData.data.errors, function (key, value) {
							errorText += '<p>' + value[0] + '</p>';
						});

						Swal.fire({
							position: 'center',
							type: 'error',
							title: '<div class="text-danger">' + resposneData.status.message + '</div>',
							html: errorText,
							showConfirmButton: true,
							scrollbarPadding: false
						});
					} else {
						Swal.fire({
							position: 'center',
							type: 'error',
							title: '<div class="text-danger">' + resposneData.status.message + '</div>',
							html: '<p>' + resposneData.data.message + '</p>',
							showConfirmButton: true,
							scrollbarPadding: false
							//timer: 3000
						});
					}
				})
				.always(function () {
				});
		}, 1000);
	};
}
