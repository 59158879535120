$(document).ready(function () {
	let htmlh = $("html").height(),
		footerh = $("footer").height(),
		windowh = $(window).height(),
		mainh = $("main").height(),
		mainxh = windowh - htmlh;

	let final = mainxh + mainh;
	$('main').css("min-height", final + 'px');
});

$(window).resize(function () {
	let htmlh = $("html").height(),
		footerh = $("footer").height(),
		windowh = $(window).height(),
		mainh = $("main").height(),
		mainxh = windowh - htmlh;

	let final = mainxh + mainh;
	$('main').css("min-height", final + 'px');

}).resize();

$(function () {
	$('[data-toggle="tooltip"]').tooltip()
});
