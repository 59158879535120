
$(document).ready(function () {
	/**
	 * multisubmit protect
	 */
	$("#frm-confirm_order").submit(function () {
		$("#jsConfirmButton").attr("disabled", true);
	});


	let invoiceField = $('#InvoiceJS');
	let invoiceData = $('#invoiceData');

	invoiceData.hide();

	if (invoiceField.prop('checked')) {
		invoiceData.show();
	}

	invoiceField.change(function () {
		if (this.checked) {
			invoiceData.show();
		} else {
			invoiceData.hide();
		}
	});


});

